
	import { Component, InjectReactive, Vue } from 'vue-property-decorator';

	import { HomepageSection } from '@/models/store/organism.interface';
	import TopicCardsSection from '@/components/homepage/TopicCardsSection.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import ExploreItemTypeBanner from '@/components/banners/ExploreItemTypeBanner.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import HomepagePackList from '@/components/pixcap-library/library-packs/HomepagePackList.vue';
	import AdvertiseSection from '@/components/homepage/AdvertiseSection.vue';
	import CompatibleSection from '@/components/homepage/CompatibleSection.vue';
	import PageHeading from '@/components/pixcap-library/shared/PageHeading.vue';

	// Partners logo
	import IconGoogleGrayed from '@pixcap/ui-library/components/Icons/brands/IconGoogleGrayed.vue';
	import IconNvidiaGrayed from '@pixcap/ui-library/components/Icons/brands/IconNvidiaGrayed.vue';
	import IconGrabGrayed from '@pixcap/ui-library/components/Icons/brands/IconGrabGrayed.vue';
	import IconAirBnbGrayed from '@pixcap/ui-library/components/Icons/brands/IconAirBnbGrayed.vue';
	import IconAmazonGrayed from '@pixcap/ui-library/components/Icons/brands/IconAmazonGrayed.vue';
	import IconMicrosoftGrayed from '@pixcap/ui-library/components/Icons/brands/IconMicrosoftGrayed.vue';
	import IconBharatPeGrayed from '@pixcap/ui-library/components/Icons/brands/IconBharatPeGrayed.vue';
	import IconRedditGrayed from '@pixcap/ui-library/components/Icons/brands/IconRedditGrayed.vue';
	import IconShopeeGrayed from '@pixcap/ui-library/components/Icons/brands/IconShopeeGrayed.vue';
	import IconAdpListGrayed from '@pixcap/ui-library/components/Icons/brands/IconAdpListGrayed.vue';

	@Component({
		name: 'LibraryExploreList',
		components: {
			PageHeading,
			CompatibleSection,
			AdvertiseSection,
			HomepagePackList,
			ExploreItemTypeBanner,
			BodyText,
			TopicCardsSection,
		},
	})
	export default class LibraryExploreList extends Vue {
		LIBRARY_TAB = LIBRARY_TAB;
		HomepageSection = HomepageSection;
		partnersLogo = [
			IconGoogleGrayed,
			IconNvidiaGrayed,
			IconGrabGrayed,
			IconAirBnbGrayed,
			IconAmazonGrayed,
			IconMicrosoftGrayed,
			IconBharatPeGrayed,
			IconRedditGrayed,

			IconShopeeGrayed,
			IconAdpListGrayed,
		];

		@InjectReactive() isMobileScreen: boolean;
		@InjectReactive() windowInnerWidth: number;
	}
