
	import { Vue, Component } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';

	@Component({
		components: { BodyText, HeadingText },
	})
	export default class TopicCardsSection extends Vue {
		cards = [
			{
				thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/icons.mp4`,
				isVideoThumbnail: true,
				title: this.$t('common_elements.icons'),
				redirect: () => {
					this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } });
				},
			},
			{
				thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/characters.webp`,
				isVideoThumbnail: false,
				title: this.$t('common_elements.characters'),
				redirect: () => {
					this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_CHARACTERS } });
				},
			},
			{
				thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/mockups.webp`,
				isVideoThumbnail: false,
				title: this.$t('common_elements.mockups'),
				redirect: () => {
					this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.MOCKUPS } });
				},
			},
		];
	}
