
	import { Component, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'AdvertiseSection',
		components: { BodyText, HeadingText },
	})
	export default class AdvertiseSection extends Vue {
		firstCardImg = `${process.env.ROOT_URL}/cdn/explore/homepage/advertise/first-card-img-1.webp`;
		firstCardVideo = `${process.env.ROOT_URL}/cdn/explore/homepage/advertise/first-card-video.mp4`;
		secondCardVideo = `${process.env.ROOT_URL}/cdn/explore/banner/explore-item-type-banner/mockups.mp4`;
		thirdCardVideo = `${process.env.ROOT_URL}/cdn/explore/homepage/advertise/third-card-video.mp4`;
	}
